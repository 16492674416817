import AMapLoader from "@amap/amap-jsapi-loader";

export default function MapLoader() {
  window._AMapSecurityConfig = {
    securityJsCode: "9efba66b2e5134cbcf6b6f36a0f67c05",
  };
  return AMapLoader.load({
    "key": "9ebf06f332c9f0098c3bde5929f84651",
    "version": "2.0",
    "plugins": ["AMap.ToolBar","AMap.DistrictSearch","AMap.Geolocation","AMap.Scale","Amap.Polygon","AMap.GraspRoad","AMap.moveAnimation","AMap.MapType","AMap.MarkerCluster"],
  });
  /*return new Promise((resolve, reject) => {
    if (AMap) {
      resolve(AMap);
    } else {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://webapi.amap.com/maps?v=1.4.15&callback=initAMap&key=601afc8c3d5b1854344dd7d1a000c06c&plugin=AMap.ToolBar";
      script.onerror = reject;
      document.head.appendChild(script);

      let scriptUi = document.createElement("script");
      scriptUi.type = "text/javascript";
      scriptUi.async = true;
      scriptUi.src = "https://webapi.amap.com/ui/1.1/main-async.js";
      document.head.appendChild(scriptUi);
      window.initAMap = () => {
        window.initAMapUI();
        AMap = window.AMap;
        resolve(AMap);
      };
    }
  });*/
}
